.terms-content {
  margin-top: 20px;
}

.terms-content h5 {
  margin-top: 20px;
}

.terms-content p {
  margin-bottom: 20px;
}
