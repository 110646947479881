.header {
  width: 100%;
  background-color: black;
  line-height: 20px;
}

.logo img {
  width: 20%;
}

.menu {
  margin-bottom: 0;
}
.avatar-circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
}

.avatar-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.avatar-initials {
  font-size: 1.25rem;
  color: #fff;
  background-color: #007bff;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

.menu li {
}
.sticky__header {
  background: #f8f9fa;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.profile-photo-circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.dropdown-toggle::after {
  display: none !important;
}

.show__menu {
  display: block !important;
}

.mobile__menu {
  display: none;
}

@media (max-width: 768px) {
  .mobile__menu {
    display: block;
  }

  .navigation {
    display: none;
  }

  .show__menu {
    display: block;
  }
}
.nav__item a {
  text-decoration: none;
  color: #fff;
  font-weight: 500;
  font-size: 1.1rem;
}

.nav__item a:hover {
  color: inherit;
}

.nav__item a.active__link {
  color: var(--secondary-color);
}

.mobile__menu {
  font-size: 1.3rem;
  color: black;
  display: none;
}

.mobile__menu i {
  font-size: 1.3rem;
  color: var(--heading-color);
  cursor: pointer;
}

.sticky__header {
  width: 100%;
  height: 80px;
  line-height: 80px;
  background: black;
  position: sticky;
  top: 0;
  z-index: 999;
  box-shadow: 3px 3px 8px -3px #ddd;
}

.container-contained {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

@media only screen and (min-width: 992px) {
  h5 {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 992px) {
  .logo img {
    width: 40%;
  }

  .navigation {
    width: 100%;
    height: 66%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    display: none;
  }

  .menu {
    position: absolute;
    top: 0;
    right: 0;
    width: 30%;
    height: 50%;
    padding-top: 0rem;
    margin-top: 5rem;
    padding-bottom: 0.5rem;
    background: lightgray;
    flex-direction: column;
    border-radius: 1.5rem;
    justify-content: center;
    z-index: 9999;
    animation-name: slideInRight;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    line-height: 0.5rem;
    right: 0px;
    padding-left: 0;
  }

  .show__menu {
    display: block;
  }

  .mobile__menu {
    display: block;
  }

  /* Fix sticky header for small devices */
  .sticky__header {
    position: sticky;
  }
}

@media only screen and (max-width: 768px) {
  .logo img {
    width: 45%;
  }

  .menu {
    width: calc(100% - 65%);
  }
}

@media only screen and (max-width: 576px) {
  .logo img {
    width: 70%;
  }

  .navigation {
    width: 100%;
    height: 66%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    display: none;
  }

  .menu {
    position: absolute;
    top: 0;
    right: 0;
    width: 45%;
    height: 60%;
    border-radius: 1.5rem;
    padding-bottom: 0.5rem;
    background: lightgray;
    flex-direction: column;
    justify-content: center;
    z-index: 9999;
    animation-name: slideInRight;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    line-height: 0.5rem;
    right: 25px;
  }

  .show__menu {
    display: block;
  }

  .mobile__menu {
    display: block;
  }

  /* Fix sticky header for extra small devices */
  .sticky__header {
    position: sticky;
  }
}

@media only screen and (max-width: 376px) {
  .logo img {
    width: 70%;
  }

  .menu {
    width: 50%;
    height: 68%;
  }
}

.nav__right h5 {
  font-size: 0.8rem;
}

.nav__btns button {
  font-size: 0.8rem;
}

@keyframes slideInRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
.mobile__header-lines {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 24px;
  width: 30px;
  cursor: pointer;
}

.mobile__header-line {
  background-color: #333;
  height: 3px;
  border-radius: 2px;
}
