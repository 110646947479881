.admin-wrapper {
  min-height: calc(100vh - 60px - 60px);
  margin-top: 60px;
}
.admin-div {
  margin-left: 88vh;
}
.admin-card {
  transition: transform 0.3s ease;
  cursor: pointer;
  margin-bottom: 10vh;
  border: 2px solid #000;
  border-radius: 0.5rem;
  padding: 0.5rem;
  margin-left: 33px;
}

.admin-card .card {
  width: 100%;
  padding-top: 100%;
  position: relative;
}

.admin-card .card-body {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.admin-card .stretched-link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

/* Sidebar styling */
.sidebar {
  position: relative;
  top: 60px; /* Adjust based on actual header height */
  bottom: 60px; /* Adjust based on actual footer height */
  width: 250px; /* Adjust as needed */
  background-color: #f8f9fa; /* Light color */
  color: #000; /* Text color */
  overflow-y: auto;
}

/* Main content styling */
.main-content {
  padding-top: 2rem;
}

/* NavLink styling */
.nav-link {
  color: #000;
  text-transform: uppercase;
  padding: 1rem 0 1rem 1.5rem;
  display: block;
}

.nav-link:hover,
.nav-link.active {
  background-color: #e9ecef;
  border-top-left-radius: 2rem;
  border-bottom-left-radius: 2rem;
}

h5 {
  margin-left: 1.5rem;
}

.admin-card:hover {
  transform: scale(1.05);
}
@media (max-width: 768px) {
  .admin-wrapper {
    min-height: calc(100vh - 60px);
    margin-top: 60px;
  }

  .admin-div {
    margin-left: 0;
  }

  .admin-card {
    margin-bottom: 20px;
    margin-left: 0;
    border: 2px solid #000;
    border-radius: 0.5rem;
    padding: 0.5rem;
    width: 100%;
  }

  .admin-card .card {
    width: 100%;
    padding-top: 100%;
    position: relative;
  }

  .admin-card .card-body {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  h5 {
    margin-left: 1rem;
    text-align: center;
  }
}

/* Larger screens (desktop) */
@media (min-width: 769px) {
  .admin-wrapper {
    min-height: calc(100vh - 60px);
    margin-top: 60px;
  }

  .admin-div {
    margin-left: 88vh;
  }

  .admin-card {
    margin-bottom: 10vh;
    margin-left: 33px;
  }
}
