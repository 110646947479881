/* Queries.css */
.queries-container {
  margin-top: 2rem;
}

.queries-table thead {
  background-color: #343a40; /* Dark background for the header */
  color: white;
}

.queries-table tbody tr:nth-child(even) {
  background-color: #f8f9fa; /* Light grey background for even rows */
}

.queries-table tbody tr:nth-child(odd) {
  background-color: #ffffff; /* White background for odd rows */
}

.queries-table td {
  vertical-align: middle;
}

.action-buttons {
  display: flex;
  gap: 0.5rem;
}

.action-buttons .btn {
  border-radius: 0.25rem; /* Rounded corners for buttons */
}
