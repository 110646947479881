.profile-wrapper {
  margin-top: 20px;
}

.sidebar {
  padding: 20px;
  border-right: 1px solid #ddd;
}

.sidebar .nav-link {
  font-size: 18px;
  margin-bottom: 10px;
}

.main-content {
  padding: 20px;
}

.main-content .tab-content {
  margin-top: 20px;
}
