/* BlogCreate.css */

/* Sidebar Styles */
.sidebar {
  border-left: 1px solid #d1d5db;
  padding-left: 1.5rem;
  background-color: #f9fafb;
}

/* Form Group Styles */
.form-group {
  margin-bottom: 1.5rem;
}

.form-group img {
  border: 1px solid #d1d5db;
  border-radius: 4px;
  margin-top: 0.5rem;
}

/* Button Styles */
button {
  margin-top: 1rem;
}

button.secondary {
  background-color: #f8f9fa;
  border: 1px solid #ced4da;
  color: #495057;
}

button.secondary:hover {
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

button.primary {
  background-color: #007bff;
  border: 1px solid #007bff;
  color: #ffffff;
}

button.primary:hover {
  background-color: #0056b3;
  border-color: #004085;
}

/* Dynamic Meta Fields Styles */
.meta-fields-container {
  margin-top: 2rem;
}

.meta-fields-container h4 {
  color: #007bff;
  margin-bottom: 1rem;
}

.meta-fields-container .form-group {
  margin-bottom: 1rem;
}

.meta-fields-container .remove-meta-field-button,
.meta-fields-container .add-meta-field-button {
  margin-top: 0.5rem;
}

/* Paragraph Styles */
.paragraph-container {
  margin-bottom: 2rem;
}

.paragraph-container .remove-paragraph-button {
  margin-top: 0.5rem;
}

.paragraph-container .image-preview {
  border: 1px solid #d1d5db;
  border-radius: 4px;
  margin-top: 0.5rem;
}

/* Form Control Styles */
.form-control {
  border-radius: 4px;
  border: 1px solid #ced4da;
}

.form-control:focus {
  box-shadow: none;
  border-color: #007bff;
}

/* Additional Styles for Sections */
.paragraph-section img,
.paragraph-section textarea,
.meta-field-section input {
  margin-top: 0.5rem;
}

.meta-field-section button,
.category-section button,
.tag-section button {
  margin-top: 0.5rem;
}
