.privacy-content {
  margin-top: 20px;
}

.privacy-content h5 {
  margin-top: 20px;
}

.privacy-content p {
  margin-bottom: 20px;
}
