.hero__subtitle img{
    width: 2.3rem;
    height: 2.3rem;
}

.hero__content h1{
    font-size: 2.2rem;
    font-weight: 500;
    margin-top: 1rem;
    margin-bottom: 1rem;
    color: var(--heading-color);
}

.hero__content h1 .highlight{
    color: var(--secondary-color);
}

.hero__content p{
    font-size: 1rem;
    color: var(--text-color);
    line-height: 2rem;
}

.hero__img-box img, .hero__img-box video{
    width: 100%;
    height: 350px;
    object-fit: cover;
    border-radius: 20px;
    border: 2px solid var(--secondary-color);
}

.hero__content{
    padding-top: 2rem;
}

.hero__img-box{
    padding-top: 2rem;
}

.services__subtitle{
    font-size: 1.8rem;
    font-weight: 500;
    color: #ee6e6e;
    font-family: var(--subtitle-font-name);
}

.services__title{
    font-size: 2rem;
    font-weight: 500;
}

.featured__tour-title{
    font-size: 2.5rem;
    font-weight: 500;
}

.experience__content h2{
    font-size: 2.5rem;
    margin-top: 1rem;
}

.experience__content p{
    font-size: 1rem;
    color: var(--text-color);
    line-height: 2rem;
    margin-top: 1rem;
}

.counter__wrapper{
    margin-top: 2rem;
}

.counter__box span{
    width: 70px;
    height: 70px;
    margin: auto;
    align-items: center;
    display: flex;
    justify-content: center;
    background: var(--primary-color);
    color: #fff;
    font-size: 1.5rem;
    font-weight: 600;
    border-radius: 10px 5px 10px 5px;
}

.counter__box h6{
    font-size: 1.1rem;
    color: var(--text-color);
    margin-top: 1rem;
}

.experience__img img{
    width:90%;
}

.gallery__title{
    font-size: 2.3rem;
    margin-bottom: 2.5rem;
    margin-top: 1rem;
}

.masonry__img{
    transition: .3s;
}

.masonry__img:hover{
    transform: scale(1.1);
}

.testimonials__title{
    font-size: 2.3rem;
    margin-top: 1rem;
}

@media only screen and (max-width: 992px) {
    .hero__content h1{
        font-size: 2.7rem;
    }

    .hero__img-box{
        display: none;
    }

    .video-box{
        display: block;
    }

    .services__title, .featured__tour-title,  .gallery__title, .testimonials__title
    , .experience__content h2{
        font-size: 2rem;
    }

    .experience__img{
        margin-top: 2rem;
    }
}

@media only screen and (max-width: 576px) {

    .hero__content{
        padding-top: .2rem;
    }

    .hero__content h1{
        font-size: 1.8rem;
    }

    .services__title, .featured__tour-title, .gallery__title, .testimonials__title,
    .experience__content h2
    {
        font-size: 1.5rem;
    }

    .counter__box h6{
        font-size: 0.7rem;

    }
}